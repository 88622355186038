import { config } from '@/config';
import * as Request from './request';

const ApiPaths = {
    PIN_API: '/api/common/pin',
    LOGIN: '/api/user',
    WX_LOGIN: '/api/wx/login',
    USER_INFO: '/api/user/info', 
    USER_WORKS: '/api/user/signUpWorks',
    UPDATE_USER_INFO: '/api/user/info',
    GET_WORKS: '/api/work',
    SIGN_UP_WORK: '/api/user/signUpWork',
    CHECK_HAD_SIGN_UP_WORK: '/api/work/check/have',

    CUSTOM_PAGE: '/api/customPageText',
    SET_CUSTOM_PAGE: '/api/admin/updateCustomPage',

    ADMIN_LOGIN: '/api/admin',
    ADMIN_LOGOUT: '/api/logout',
    ADMIN_ADD_WORK: '/api/admin/addWork',
    ADMIN_EDIT_WORK: '/api/admin/updateWork',
    ADMIN_DELETE_WORK: '/api/admin/deleteWork',
    ADMIN_GET_WORK_USERS: '/api/admin/getApplicants',
    ADMIN_DELETE_APPLICANT: '/api/admin/deleteApplicant',
    ADMIN_GET_USER_LIST: '/api/admin/users',
    
    // @hlh
    // 管理员修改用户信息 => 是否加入黑名单 & 给用户添加备注
    ADMIN_UPDATE_USERINFO: '/api/admin/updateUserInfo',
    // 管理员将黑名单用户恢复到正常用户
    ADMIN_RELIEVE_USER: '/api/admin/relieveUser',
    // 管理员获取黑名单用户
    ADMIN_GET_BLACK_USER: '/api/admin/findBlackUserInfo',
    // 查询所有用户中符合条件的用户组
    ADMIN_GET_USERS_BY_FILTER: '/api/admin/findMoreByUsers',
    // 查询所有黑名单用户中符合条件的用户组
    ADMIN_GET_BLACK_USERS_FILTER: '/api/admin/findMoreByBlackUsers',

};

let basicPah = '';
// 初始化API路径
if (config.env === 'dev') {
    basicPah = 'http://localhost:8080';
}
if (basicPah) {
    Object.keys(ApiPaths).forEach((key: string) => {
        const item: any = ApiPaths;
        item[key] = `${basicPah}${item[key]}`; 
    });
}

export default {
    // @hlh
    // 管理员修改用户信息
    adminUpdateUserInfo: (data: any) => {
        return Request.request({url: ApiPaths.ADMIN_UPDATE_USERINFO, method: Request.RequestMethods.PUT, data});
    },
    // 释放黑名单用户
    adminRelieveUser:(userId: Number) => {
        return Request.request({url: ApiPaths.ADMIN_RELIEVE_USER, method: Request.RequestMethods.PUT, data: {userId}});
    },
    // 获取黑名单用户
    getBlackUsers:(params: {currentPage: number, pageSize: number}) => {
        return Request.request({url: ApiPaths.ADMIN_GET_BLACK_USER, method: Request.RequestMethods.GET, params});
    },
    // 查询符合条件的所有用户
    getUsersByFilter: (params: {currentPage: number, pageSize: number, filterData: string})  => {
        return Request.request({url: ApiPaths.ADMIN_GET_USERS_BY_FILTER, method: Request.RequestMethods.GET, params});
    },
    // 查询符合条件的黑名单用户
    getBlackUsersByFilter: (params: {currentPage: number, pageSize: number, filterData: string})  => {
        return Request.request({url: ApiPaths.ADMIN_GET_BLACK_USERS_FILTER, method: Request.RequestMethods.GET, params});
    },
        
    // @hlh end

    // 获取验证码
    getPinCode: () => {
        return Request.request({url: ApiPaths.PIN_API, method: Request.RequestMethods.POST});
    },
    
    //  微信登录
    wxLogin: (code: string) => {
        return Request.request({url: ApiPaths.WX_LOGIN, method: Request.RequestMethods.POST, data: {code}});
    },

    // 用户密码登录
    userLogin: (data: any) => {
        return Request.request({url: ApiPaths.LOGIN, method: Request.RequestMethods.POST, data});
    },
    
    // 获取用户信息
    getUserInfo: () => {
        return Request.request({url: ApiPaths.USER_INFO, method: Request.RequestMethods.GET});
    },

    // 获取用户报名过的工作
    getUserWorks: () => {
        return Request.request({url: ApiPaths.USER_WORKS, method: Request.RequestMethods.GET});
    },
    
    // 更新用户信息
    updateUserInfo: (data: any) => {
        return Request.request({url: ApiPaths.USER_INFO, method: Request.RequestMethods.POST, data});
    },

    // 获取工作列表
    getWorkList: (params: {currentPage: number, pageSize: number, searchValue?: string, workType: string}) => {
        return Request.request({url: ApiPaths.GET_WORKS, method: Request.RequestMethods.GET, params});
    },

    // 报名工作
    signUpWork: (workId: string) => {
        return Request.request({url: ApiPaths.SIGN_UP_WORK, method: Request.RequestMethods.PUT, data: {workId}});
    },

    // 检查是否已经报名
    checkHadSignUpWork: (workId: string) => {
        return Request.request({url: ApiPaths.CHECK_HAD_SIGN_UP_WORK, method: Request.RequestMethods.GET, params: {workId}});
    },


    // 社群自定义页面内容
    getCustomPage: () => {
        return Request.request({url: ApiPaths.CUSTOM_PAGE, method: Request.RequestMethods.GET});
    },

    // 社群自定义内容设置
    setCustomPage: (text: string) => {
        return Request.request({url: ApiPaths.SET_CUSTOM_PAGE, method: Request.RequestMethods.PUT, data: {text}});
    },


    //管理员登录
    adminLogin: (data: any) => {
        return Request.request({url: ApiPaths.ADMIN_LOGIN, method: Request.RequestMethods.POST, data});
    },

    //管理员退出登录
    adminLogout: () => {
        return Request.request({url: ApiPaths.ADMIN_LOGOUT, method: Request.RequestMethods.GET});
    },

    //管理员编辑工作
    adminAddWork: (data: any) => {
        return Request.request({url: ApiPaths.ADMIN_ADD_WORK, method: Request.RequestMethods.POST, data});
    },

    //管理员编辑工作
    adminEditWork: (data: any) => {
        return Request.request({url: ApiPaths.ADMIN_EDIT_WORK, method: Request.RequestMethods.PUT, data});
    },

    // 管理员删除工作
    adminDeleteWork: (workId: number) => {
        return Request.request({url: ApiPaths.ADMIN_DELETE_WORK, method: Request.RequestMethods.DELETE, data: { workId }});
    },

    // 管理员删除工作
    adminGetWorkUsers: (workId: number) => {
        return Request.request({url: ApiPaths.ADMIN_GET_WORK_USERS, method: Request.RequestMethods.GET, params: { workId }});
    },
    
    // 管理员删除报名者
    adminDeleteApplicant: (userId: number, workId: number) => {
        return Request.request({url: ApiPaths.ADMIN_DELETE_APPLICANT, method: Request.RequestMethods.DELETE, data: { userId, workId }});
    },

    // 管理员获取用户列表
    adminGetUserList: (params: {currentPage: number, pageSize: number}) => {
        return Request.request({url: ApiPaths.ADMIN_GET_USER_LIST, method: Request.RequestMethods.GET, params});
    },
};



import { reactive } from '@vue/reactivity';
import UserInfo from '@/components/user/User.vue';
import UserWorkList from '@/components/user/UserWorkList.vue';
import WorkList from '@/components/user/WorkList.vue';
import WorkInfo from '@/components/user/WorkInfo.vue';
import JoinGroup from '@/components/user/JoinGroup.vue';
import SignUpTipDialog from '@/components/user/dialog/SignUpTipDialog.vue';
import Api from '@/common/api';
import Utils from '@/common/utils';
import {
    HomeOutlined,
    WechatOutlined,
    UserOutlined,
    LeftOutlined,
} from '@ant-design/icons-vue';
import { inject } from '@vue/runtime-core';
import { message } from 'ant-design-vue';
import { ref } from 'vue';

export default ({
    name: 'Index',
    components: {
        UserInfo,
        UserWorkList,
        WorkList,
        WorkInfo,
        JoinGroup,
        HomeOutlined,
        WechatOutlined,
        UserOutlined,
        LeftOutlined,
        SignUpTipDialog,
    },
    setup() {
        const globalData: any = inject('globalData');
        const workListRef: any = ref();
        let isWeChat: boolean = false;
        let pageHistory: string[] = reactive([]);
        const signUpTipDialog: any = ref<null | HTMLElement>(null);

        const data: any = reactive({
            title: '白米兼职',
            activeKey: 'home',
            workInfoData: null,
            isHadSignUpWork: false,
        });

        let userCode = '';
        const tokenRegexp = new RegExp('(token=).*$', 'g');
        const matchData = Utils.getUrl().match(tokenRegexp);
        if (matchData && matchData?.length > 0) {
            userCode = matchData[0].replace('token=', '');
            window.localStorage.setItem('userToken', userCode);
            if (userCode) {
                isWeChat = true;
            }
        }

        // 获取用户信息
        const getUserInfo = () => {
            Api.getUserInfo().then((response: any) => {
                if (response.data.code !== 100) {
                    console.log('数据异常');
                    return;
                }
                globalData.userInfo.value = response.data.data;
            }).catch((reason: any) => {
                console.error(reason);
            });
        };

        // 获取用户报名过的工作信息
        const getUserWorks = () => {
            Api.getUserWorks().then((response: any) => {
                if (response.data.code !== 100) {
                    console.log('数据异常');
                    return;
                }
                globalData.userWorks.value = response.data.data?.works;
            }).catch((reason: any) => {
                console.error(reason);
            });
        };

        getUserInfo();
        getUserWorks();

        const activeKeyChange = (key: string) => {
            switch(key) {
                case 'home':
                    data.title = '兼职';
                    break;
                case 'group':
                    data.title = '兼职';
                    break;
                case 'user':
                    data.title = '个人中心';
                    break;
                case 'work':
                    data.title = '兼职详情';
                    break;
                case 'userWorks':
                    data.title = '已报名工作';
                    break;
            }
            data.activeKey = key;
        };

        const checkHadSignUpWork = () => {
            Api.checkHadSignUpWork(data.workInfoData.workId).then((response: any) => {
                const respData = response.data;
                if (respData.code === 100) {
                    data.isHadSignUpWork = respData.data;
                } else {
                    data.isHadSignUpWork = false;
                }
            });
        };

        const showUserWorks = () => {
            pageHistory.push(data.activeKey);
            activeKeyChange('userWorks');
        };

        const workCardClick = (values: any) => {
            data.workInfoData = values;
            checkHadSignUpWork();
            pageHistory.push(data.activeKey);
            activeKeyChange('work');
        };

        const pageBack = () => {
            data.workInfoData = null;
            data.isHadSignUpWork = false;
            const pageIndex = pageHistory.pop();
            activeKeyChange(pageIndex ?? 'home');
        };

        const styleCanShowByTag = (tags: string[]) => {
            if (!tags.includes(data.activeKey)) {
                return 'display: none';
            }
            return 'true';
        };

        const signUpClick = () => {
            const userInfo = globalData.userInfo.value;
            if (!(userInfo.name && userInfo.sex && userInfo.phone && userInfo.wechat && userInfo.age)) {
                message.warn('请填写个人信息后可报名工作');
                data.activeKey = 'user';
                return;
            }

            // @hlh
            // 被拉黑的人员无法报名
            if (userInfo.blacklisted == 1) {
                message.warn('你已被拉黑，请联系管理员！');
                return;
            }

            const signup = () => {
                Api.signUpWork(data.workInfoData.workId).then((response: any) => {
                    if (response.data.code !== 100) {
                        message.error(response.data.data || response.data.msg);
                    } else {
                        message.success(response.data.msg);
                        workListRef.value.getWorkDataByPagging();
                        checkHadSignUpWork();
                        getUserWorks();
                    }
                });
            };            

            signUpTipDialog.value.showDialog({callBack: signup});
        };
        const signUpWorkButtonIsDissable = (workData: any) => {
            if (!workData || data.isHadSignUpWork) {
                return true;
            }
            return workData.total >= workData.people || workData.workState !== 'reservation';
        };
        const signUpButtonText = () => {
            return data.isHadSignUpWork ? '已报名' : '报名';
        };

        return {
            pageHistory,
            workListRef,
            data,
            styleCanShowByTag,
            activeKeyChange,
            showUserWorks,
            workCardClick,
            pageBack,
            signUpButtonText,
            signUpClick,
            signUpWorkButtonIsDissable,
            signUpTipDialog,
            isWeChat,
        };
    }
});

import axios from 'axios';

enum RequestMethods {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

interface RquestDatas {
    method: RequestMethods,
    url: string,
    headers?: any,
    data?: any,
    params?: any,
}

const request = (data: RquestDatas) => {
    return axios({
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'userToken': window.localStorage.getItem('userToken'),
            ...data.headers,
        },
        method: data.method,
        url: data.url,
        data: data.data,
        params: data.params,
        //其他相关配置
    });
};

export {
    request,
    RequestMethods,
};

import { reactive, ref } from '@vue/reactivity';
import { toRaw, UnwrapRef } from 'vue';
import Api from '@/common/api';
import { message } from 'ant-design-vue';
import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
import WorkTable from '@/components/admin/WorkTable.vue';
import UserTable from '@/components/admin/UserTable.vue';
import Custom from '@/components/admin/Custom.vue';
import EditWorkDialog from '@/components/admin/dialog/EditWorkDialog.vue';
// @hlh
import BlackUser from '@/components/admin/BlackUser.vue';

interface FormState {
    value: {
        username: string,
        password: string,
        pin: string,
    }
}

export default ({
    components: {
        WorkTable,
        UserTable,
        Custom,
        BlackUser,
        EditWorkDialog,
    },
    setup() {
        
        const loginState = ref(false);
        const selectedKeys = reactive({
            value: ['workList'],
        });

        const editWorkDialog: any = ref<null | HTMLElement>(null);
        const workTableRef: any = ref<null | HTMLElement>(null);

        const formRef = ref();
        const formState: UnwrapRef<FormState> = reactive({
            value: {
                username: '',
                password: '',
                pin: '',
            },
        });
        const formRules = {
            username: [
                { required: true, message: '帐号不能为空', trigger: 'change' },
            ],
            password: [
                { required: true, message: '密码不能为空', trigger: 'change' },
            ],
            pin: [
                { required: true, message: '验证码不能为空', trigger: 'change' },
            ],
        };

        const canAdmin = window.localStorage.getItem('canAdmin');
        if (canAdmin === 'true') {
            loginState.value = true;
        }

        const pinImage = reactive({
            pic: '',
            pinId: '',
        });

        const styleCanShowByTag = (tags: string[]) => {
            if (!tags.includes(selectedKeys.value[0])) {
                return 'display: none';
            }
            return 'true';
        };

        const menuSelect = (datas: any) => {
            console.log(datas);
        };

        const clickLogin = () => {
            console.log('login');
            formRef.value.validate().then(() => {
                const data = toRaw(formState.value);
                Api.adminLogin({...data, pinId: pinImage.pinId }).then((response: any) => {
                    const respData = response.data;
                    if (respData.code === 100) {
                        window.localStorage.setItem('userToken', respData.data);
                        window.localStorage.setItem('canAdmin', 'true');
                        loginState.value = true;
                    } else {
                        message.error(respData.msg);
                    }
                    console.log(respData);
                }).catch((e: any) => {
                    console.error(e);
                    message.error('服务器错误', 2);
                });
            }).catch((error: ValidateErrorEntity<FormState>) => {
                console.log('error', error);
                message.error('请将信息填写完整', 2);
            });

        };

        const clickLogout = () => {
            Api.adminLogout();
            window.localStorage.removeItem('userToken');
            window.localStorage.removeItem('canAdmin');
            loginState.value = false;
        };

        const getPin = () => {
            Api.getPinCode().then((response: any) => {
                const respData = response.data;
                if (respData.code === 100) {
                    pinImage.pic = respData.data.pic;
                    pinImage.pinId = respData.data.pinId;
                }
            });
        };
        if (!loginState.value) {
            getPin();
        }

        return {
            styleCanShowByTag,
            selectedKeys,
            
            loginState,
            formState,
            formRules,
            formRef,
            pinImage,
            menuSelect,
            clickLogin,
            clickLogout,
            getPin,

            editWorkDialog,

            workTableRef,
        };
    },
});

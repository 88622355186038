
import { ref } from 'vue';

export default ({
    name: 'SignUpTipDialog',
    setup() {
        let countDownId = -1;
        const visible = ref(false);
        const canClick = ref(true);
        const lastClickTime = ref(5);
        let confirmCallBack: any = () => {};

        const showDialog = (data: any) => {
            visible.value = true;
            lastClickTime.value = 5;
            canClick.value = true;
            confirmCallBack = data.callBack;
            countDownId = setInterval(() => {
                if (--lastClickTime.value <= 0) {
                    clearInterval(countDownId);
                    canClick.value = false;
                }
            }, 1000);
        };

        const closeDialog = () => {
            clearInterval(countDownId);
            visible.value = false;
        };

        const onConfirm = () => {
            closeDialog();
            confirmCallBack();
        };

        const lastTimeText = () => {
            return lastClickTime.value > 0 ? `(${lastClickTime.value})` : '';
        };

        return {
            visible,
            canClick,

            showDialog,
            closeDialog,
            onConfirm,
            lastTimeText,
        };
    },
});


import { reactive } from '@vue/reactivity';
import Api from '@/common/api';
import moment from 'moment';
import Utils from '@/common/utils';
import { ref } from 'vue';

export default ({
    props: {
        clickWorkCallBack: Function,
    },
    setup() {
        const pagging = {
            currentPage: 1,
            pageSize: 300,
            total: 0,
        };

        const timeGroup: any =  reactive({});
        const workType: any = ref<string>('daily');

        const titleText = (item: any) => {
            return item.title;
        };

        const workStateText = (item: any) => {
            switch(item.workState) {
                case 'reservation':
                    if (item.total >= item.people) {
                        return '满员';
                    }
                    return '预约中';
                case 'finished':
                    return '已结束';
                case 'edit':
                    return '编辑中';
            }
        };

        const workStateBackgroundColor = (item: any) => {
            return `background-color:  ${item.workState === 'reservation' && item.total < item.people ? '' : '#C0C0C0' }`;
        };

        const moneyText = (item: any) => {
            return `¥${item.money}/${Utils.translateMoneyUnity(item.moneyUnit)}`;
        };

        const dateText = (item: any) => {
            return moment(item.workStartDate).format('YYYY-MM-DD');
        };

        const timeRangeText = (item: any) => {
            return `${item.workStartTime}-${item.workEndTime}`;
        };

        const workNeedPeoplesText = (item: any) => {
            return `${item.total}/${item.people}人`;
        };

        const workLimitText = (item: any) => {
            return `${Utils.translatePaymentMethod(item.paymentMethod)} | ${['man', 'woman'].includes(item.sex) ? `仅${item.sex === 'man' ? '男' : '女'}生` : '男女不限'}`;
        };
        
        const scrollBottomEvent = (e: any, callBack?: () => void) => {
            const target = e.currentTarget;
            const scrollHeight = target.scrollHeight; // 总高度
            const clientHeight = target.clientHeight; // 可视高度
            const scrollTop = target.scrollTop; // 当前滚动的高度
            if (clientHeight + scrollTop >= scrollHeight - 1 ) {
                // 触底触发事件
                if (callBack) {
                    callBack();
                }
            }
        };

        const getWorkDataByPagging = () => {
            Api.getWorkList({
                currentPage: 1,
                pageSize: pagging.pageSize * pagging.currentPage,
                workType: workType.value === 'daily' ? '' : workType.value,
            }).then((response: any) => {
                const tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
                if (response.data.code === 100) {
                    const workData = response.data.data;
                    const works: any[] = workData.works;
                    const fullList: any[] = [];
                    const finishedWorks: any[] = [];
                    for (let n = 0; n < works.length; n++) {
                        const item = works[n];
                        const date = dateText(item);
                        if (date > tomorrow && workType.value === 'daily') {
                            works.splice(n, 1);
                            n--;
                            continue;
                        }

                        if (!timeGroup[date]) {
                            timeGroup[date] = [];
                        }
                        if (item.workState === 'finished') {
                            works.splice(n, 1);
                            n--;
                            finishedWorks.push(item);
                        } else if (item.people <= item.total) {
                            works.splice(n, 1);
                            n--;
                            fullList.push(item);
                        }
                    }
                    works.forEach((item: any) => {
                        timeGroup[dateText(item)].push(item);
                    });
                    fullList.forEach((item: any) => {
                        timeGroup[dateText(item)].push(item);
                    });
                    finishedWorks.forEach((item: any) => {
                        timeGroup[dateText(item)].push(item);
                    });
                    pagging.total = workData.count;
                }
            }).catch((e: any) => {
                console.error(e);
            });
        };
        getWorkDataByPagging();

        const getNewDataByScrollBottom = (e: any) => {
            scrollBottomEvent(e, () => {
                if (pagging.currentPage * pagging.pageSize < pagging.total) {
                    pagging.currentPage += 1;
                    getWorkDataByPagging();
                }
            });
        };

        const workTypeChange = (e: any) => {
            const type = e.target.value;
            console.log(type);

            Object.keys(timeGroup).forEach((key: string) => {
                delete timeGroup[key];
            });
            pagging.currentPage = 1;
            getWorkDataByPagging();
        };

        return {
            workType,
            timeGroup,
            getWorkDataByPagging,
            titleText,
            workStateText,
            workStateBackgroundColor,
            moneyText,
            dateText,
            timeRangeText,
            workNeedPeoplesText,
            workLimitText,
            getNewDataByScrollBottom,
            workTypeChange,
        };
    }
});

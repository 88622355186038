
import { reactive, ref } from '@vue/reactivity';
import Api from '@/common/api';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import Utils from '@/common/utils';
import { createVNode, inject } from 'vue';

export default ({
    setup() {
        const title = ref('报名人详情');
        const visible = ref(false);
        const userInfoVisible = ref(false);
        const currentWorkId = ref(-1);
        let dataChangeCallBack: () => void;
        const userInfo = reactive({
            value: {
                // @hlh
                userId: 0,
                userRemarks: '',
                blacklisted: 0,
            },
        });
        const userList = reactive({
            value: [],
        });
        
        const showUserInfoDialog = () => {
            userInfoVisible.value = true;
        };
        const closeUserInfoDialog = () => {
            userInfoVisible.value = false;
        };
        const closeDialog = () => {
            visible.value = false;
        }; 

        const getUserList = () => {
            Api.adminGetWorkUsers(currentWorkId.value).then((response: any) => {
                const respData = response.data;
                if (respData.code !== 100) {
                    message.error('获取数据失败');
                    return;
                } else {
                    userList.value = respData.data || [];
                    visible.value = true;
                }
            });
        };

        const showDialog = (workId: number, n: number) => {
            currentWorkId.value = workId;
            console.log('151:==> ', currentWorkId.value);
            if (n == 1) {
                isDisabled.value = true;
            }
            getUserList();
        };

        const showUserMore = (userData: any) => {
            // @hlh
            userInfo.value = userData;
            isBlacklisted.value = userData.blacklisted == 1 ? true : false;
            userRemarks.value = userData.userRemarks;
            showUserInfoDialog();
        };

        const deleteApplicant = (userData: any) => {
            Modal.confirm({
                title: `删除报名者: ${userData.name}`, 
                icon: createVNode(ExclamationCircleOutlined),
                content: '你确定要删除此报名者吗?',
                onOk: async () => {
                    await Api.adminDeleteApplicant(userData.userId, currentWorkId.value);
                    getUserList();
                    dataChangeCallBack();
                },
                onCancel: () => {},
            });
        };

        const setDataChangeCallBack = (callBack: () => void) => {            
            dataChangeCallBack = callBack;
        };

        // @hlh
        // 注入
        const isFirstLoading = inject('isFirstLoading');

        const isBlacklisted = ref(true);
        const userRemarks = ref('');
        const isDisabled = ref(false);
        const modifyUserInfo = async () => {
            await Api.adminUpdateUserInfo({ userId: userInfo.value!.userId, blacklisted:  isBlacklisted.value ? 1 : 0, userRemarks: userRemarks.value});
            message.success('修改用户信息成功！');
            // 数据更新
            userInfo.value.userId = userInfo.value!.userId;
            userInfo.value.userRemarks = userRemarks.value;
            userInfo.value.blacklisted = isBlacklisted.value ? 1 : 0;
            // 改变黑名单页面刷新
            console.log('isFirstLoading.value => ', (isFirstLoading as { value: number }).value);
            (isFirstLoading as { value: number }).value = -1;

            closeUserInfoDialog();
        };

        return {
            // @hlh
            isDisabled,
            isBlacklisted,
            userRemarks,
            modifyUserInfo,

            title,
            visible,
            userInfoVisible,
            userInfo,
            userList,
            setDataChangeCallBack,
            sexText: Utils.sexText,
            formTime: Utils.formatTime,
            showUserInfoDialog,
            closeUserInfoDialog,
            showDialog,
            closeDialog,
            showUserMore,
            deleteApplicant,
        };
    },
});


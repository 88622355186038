
import { ref } from 'vue';
import Api from '@/common/api';
import { message } from 'ant-design-vue';

export default ({
    setup() {
        const text = ref('');

        Api.getCustomPage().then((response: any) => {
            text.value = response.data.data;
        });

        const saveCustomPage = () => {
            Api.setCustomPage(text.value).then(() => {
                message.success('保存成功');
            }).catch((e: any) => {
                console.error(e);
                message.error('保存失败');
            });
        };

        return {
            text,
            saveCustomPage,
        };
    },
});

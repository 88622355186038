import { createApp } from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import router from './router';
import 'ant-design-vue/dist/antd.css';
import '@/common/css/common.css';

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// vConsole.setOption('maxLogNumber', 5000);

export const VueApp = createApp(App);
VueApp.use(router);
VueApp.use(Antd);
VueApp.mount('#app');


import moment from 'moment';
import Utils from '@/common/utils';

export default ({
    name: 'WorkInfo',
    props: {
        workData: Object,
    },
    setup() {

        

        const formatData = (date: string) => {
            return moment(date).format('YYYY-MM-DD');
        };

        const workLimitText = (sex: any) => {
            return `${['man', 'woman'].includes(sex) ? `仅${sex === 'man' ? '男' : '女'}生` : '男女不限'}`;
        };

        return {
            Utils,

            formatData,
            workLimitText,
        };
    },
});


import { reactive, ref } from '@vue/reactivity';
import { provide, toRaw, watch } from 'vue';
import { ModelData } from '@/store/dataModel';

export default ({
    setup() {
        const globalData = reactive(ModelData);
        const myWindow: any = window;
        myWindow.globalData = globalData;
        provide('globalData', globalData);

        // @hlh 主要用于监测 BlackUser 页面数据变化
        const isFirstLoading = ref(-1);
        provide('isFirstLoading', isFirstLoading);

        // 监听全局变量变化
        watch(globalData, (newValue, oldValue) => {
            console.log('globalData change:',toRaw(newValue), toRaw(oldValue));
        });

        document.title = '白米兼职';
        return {};
    },
});


import { ref } from 'vue';
import Api from '@/common/api';

export default ({
    setup() {
        const text = ref('');
        Api.getCustomPage().then((response: any) => {
            text.value = response.data.data;
        });
        return {
            text,
        };
    },
});

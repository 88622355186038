
import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
import moment from 'moment';
import { reactive, ref, toRaw, UnwrapRef } from 'vue';
import Utils from '@/common/utils';

interface FormState {
    value: {
        title: string,
        people: number,
        sex: string,
        description: string,
        notice: string,
        businessName: string,
        position: string,
        contactName: string,
        contactPhone: string,
        contactWechat: string,
        workStartDate: string,
        workStartTime: string,
        workEndTime: string,
        workEndDate: string,
        money: number,
        moneyUnit: string,
        paymentMethod: string,
        workCycleType: string,
        workState: string,
        workType: string,
    },
}

export default ({
    name: 'EditWorkDialog',
    setup() {
        const formRef = ref();
        const title = ref('添加工作');
        const sexSelectOptions = [
            {value: 'all', label: '男女不限'},
            {value: 'man', label: '仅男生'},
            {value: 'woman', label: '仅女生'},
        ];
        const moneyUnitSelectOptions = [
            {value: 'day', label: '天'},
            {value: 'week', label: '周'},
            {value: 'month', label: '月'},
        ];
        const paymentMethodSelectOptions = [
            {value: 'day', label: '日结'},
            {value: 'week', label: '周结'},
            {value: 'month', label: '月结'},
        ];
        const workCycleTypeSelectOptions = [
            {value: 'day', label: '日结'},
            {value: 'week', label: '周结'},
            {value: 'month', label: '月结'},
        ];

        const formBasicData = {
            title: '',
            people: 1,
            sex: 'all',
            description: '',
            notice: '',
            businessName: '',
            position: '',
            contactName: '',
            contactPhone: '',
            contactWechat: '',
            workStartDate: moment().format(),
            workStartTime: moment().format(),
            workEndDate: moment().format(),
            workEndTime: moment().format(),
            money: 0,
            moneyUnit: 'day',
            paymentMethod: 'day',
            workCycleType: 'day',
            workState: 'edit',
            workType: 'daily',
        };
        const formState: UnwrapRef<FormState> = reactive({
            value:  Utils.cloneData(formBasicData)
        });

        const visible = ref(false);
        // eslint-disable-next-line no-unused-vars
        let confirmCallBack: undefined | ((work: any) => void);

        const editFormateData = (workData: any) => {
            let data = Utils.cloneData(formBasicData);
            title.value = workData?.workId ? '编辑工作' : '添加工作';
            if (workData) {
                data = Utils.cloneData(workData);
                data.workStartTime = moment(data.workStartTime, 'HH:mm');
                data.workEndTime = moment(data.workEndTime, 'HH:mm');
            }
            return data;
        };

        const confirmFormate = (workData: any) => {
            let data = null;
            if (workData) {
                data = Utils.cloneData(workData);
                data.money = parseInt(data.money, 10);
                data.people = parseInt(data.people, 10);

                data.workStartTime = moment(data.workStartTime).format('HH:mm');
                data.workEndTime = moment(data.workEndTime).format('HH:mm');
                data.workStartDate = moment(data.workStartDate).format('YYYY-MM-DD');
                data.workEndDate = moment(data.workEndDate).format('YYYY-MM-DD');
            }
            return data;
        };

        // eslint-disable-next-line no-unused-vars
        const showDialog = (workData: any, callBack?: (work: any) => void) => {
            confirmCallBack = callBack;
            formState.value = editFormateData(workData);
            visible.value = true;
        };
        const closeDialog = () => {
            visible.value = false;
        };
        const confirm = () => {
            console.log('save');
            formRef.value.validate().then(() => {
                const formData = toRaw(formState.value);
                const result = confirmFormate(formData);
                if (confirmCallBack) {
                    confirmCallBack(result);
                }
                console.log(result);
            }).catch((error: ValidateErrorEntity<FormState>) => {
                console.log(error);
            });

            closeDialog();
        };

        return {
            formRef,
            sexSelectOptions,
            moneyUnitSelectOptions,
            paymentMethodSelectOptions,
            workCycleTypeSelectOptions,
            formState,
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },

            title,
            visible,
            showDialog,
            closeDialog,
            confirm,
        };
    }
});

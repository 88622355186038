<template>
  <div class="user-work-list">
      <a-list item-layout="horizontal" :data-source="works.value">
            <template #renderItem="{ item }">
                <a-list-item>
                    <div class="item-value" @click="clickWorkCallBack(item)">
                        <div class="text">
                            <div class="sign-up-time">
                                报名时间: {{ timeText(item.signUpTime) }}
                            </div>
                            <div class="time">
                                标题: {{ item.title }}
                            </div>
                        </div>
                        <RightOutlined class="icon-size"/>
                    </div>
                </a-list-item>
            </template>
        </a-list>
  </div>
</template>

<script>
import { inject, reactive, watch } from 'vue';
import Utils from '@/common/utils';
import moment from 'moment';
import {
    RightOutlined
} from '@ant-design/icons-vue';

export default {
    components: {
        RightOutlined,
    },
    props: {
        clickWorkCallBack: Function,
    },
    setup: () => {
        const globalData = inject('globalData');
        const works = reactive([]);
        watch(globalData.userWorks, (newValue) => {
            const userWorks = Utils.cloneData(newValue.value);
            works.value = userWorks;
        });

        const timeText = (time) => {
            return moment(time).format('YYYY-MM-DD HH:mm:ss');
        };

        return {
            works,
            timeText,
        };
    }
};
</script>

<style scoped>

.user-work-list {
    height: 100%;
    overflow-y: auto;
}

.user-work-list :deep(.ant-list-item) {
    border-bottom: 1px solid #cccccc;
}

.user-work-list .item-value {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.item-value .text {
    width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text .sign-up-time, .text .time {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
}

.icon-size {
    font-size: 18px;
    margin: 0;
}

</style>

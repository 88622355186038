
import { reactive, ref, toRaw } from '@vue/reactivity';
import Api from '@/common/api';
import { message, Modal } from 'ant-design-vue';
import moment from 'moment';
import EditWorkDialog from '@/components/admin/dialog/EditWorkDialog.vue';
import ApplicantsDialog from '@/components/admin/dialog/ApplicantsDialog.vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';

export default ({
    name: 'WorkTable',
    components: {
        EditWorkDialog,
        ApplicantsDialog,
    },
    setup() {
        const searchValue = ref('');

        const pagging = reactive({
            currentPage: 1,
            pageSize: 10,
            total: 0,
        });
        const columns = [
            {
                key: 'index',
                dataIndex: 'index',
                title: '序号',
                align: 'center',
                width: 70,
                slots: { customRender: 'index' },
            },
            {
                key: 'title',
                dataIndex: 'title',
                title: '标题',
                width: 200,
            },
            {
                key: 'peoples',
                dataIndex: 'peoples',
                title: '报名人数',
                slots: { customRender: 'peoples' },
            },
            {
                key: 'businessName',
                dataIndex: 'businessName',
                title: '商家',
            },
            {
                key: 'address',
                dataIndex: 'address',
                title: '位置',
            },
            {
                key: 'time',
                dataIndex: 'time',
                title: '上岗时间',
            },
            {
                key: 'state',
                dataIndex: 'state',
                title: '状态',
                slots: { customRender: 'state' },
            },
            {
                key: 'type',
                dataIndex: 'type',
                title: '工作类型',
                slots: { customRender: 'type' },
            },
            {
                key: 'action',
                title: '操作',
                slots: { customRender: 'action' },
            },
        ];
        const tableData: any = reactive({
            value: [],
        });

        const editDialog: any = ref<null | HTMLElement>(null);
        const applicantsDialog: any = ref<null | HTMLElement>(null);

        // 获取任务列表, 分页查询
        const getWorks = () => {
            Api.getWorkList({...pagging, searchValue: searchValue.value, workType: ''}).then((response: any) => {
                const respData = response.data;
                if (response.data.code === 100) {
                    pagging.total = respData.data.count;
                    const works = respData.data.works;
                    if (works && works.length > 0) {
                        const workItems: any[] = [];
                        works.forEach((item: any) => {
                            workItems.push({
                                key: item.workId,
                                workId: item.workId,
                                blacklisted: item.blacklisted,
                                title: item.title,
                                people: item.people,
                                businessName: item.businessName,
                                total: item.total,
                                address: item.position,
                                userRemarks: item.userRemarks,
                                time: `${moment(item.workStartDate).format('YYYY-MM-DD')} ${item.workStartTime}`,
                                type: item.workType,
                                state: item.workState,
                                resource: item,
                            });
                        });
                        tableData.value = workItems;
                    } else {
                        tableData.value = [];
                    }
                    console.log(respData.data);
                } else {
                    message.error(respData.msg);
                }
            }).catch((error: any) => {
                message.error(error);
            });
        };
        getWorks();

        const getFirstPageWorks = () => {
            pagging.currentPage = 1,
            getWorks();
        };

        const workPeoples = (item: any) => {
            return item.total + '/' + item.people;
        };
        const workStateText = (item: any) => {
            let text = '';
            switch(item.state) {
                case 'reservation':
                    if (item.total >= item.people) {
                        text = '满员';
                    } else {
                        text = '预约中';
                    }
                    break;
                case 'finished':
                    text = '已结束';
                    break;
                case 'edit':
                    text = '编辑中';
                    break;
            }
            return text;
        };
        const workStateColor = (item: any) => {
            switch(item.state) {
                case 'reservation':
                    if (item.total >= item.people) {
                        return 'red';
                    }
                    return 'green';
                case 'finished':
                    return 'blue';
                case 'edit':
                    return 'grey';
            }
        };

        const workTypeColor = (item: any) => {
            switch(item.type) {
                case 'daily':
                    return 'green';
                case 'weekend':
                    return 'blue';
                case 'regular':
                    return 'red';
            }
        };

        const workTypeText = (item: any) => {
            const type = item.type;
            switch (type) {
                case 'daily':
                    return '日结';
                case 'weekend':
                    return '周末';
                case 'regular':
                    return '固定';
            }
        };

        // 分页查询
        const changePage = (page: number, pageSize: number) => {
            pagging.currentPage = page;
            pagging.pageSize = pageSize;  
            getWorks();
        }; 

        // 显示工作报名者
        const showUsersClick = (record: any) => {
            if (applicantsDialog) {
                applicantsDialog.value.setDataChangeCallBack(getWorks);
                applicantsDialog.value.showDialog(toRaw(record).workId, 1);
            }
        };


        // 添加工作
        const addWorkClick = () => {
            editDialog.value.showDialog(null, (result: any) => {
                result.workStartDate = moment(result.workStartDate, 'YYYY-MM-DD');
                result.workEndDate = moment(result.workEndDate, 'YYYY-MM-DD');
                Api.adminAddWork(result).then((response: any) => {
                    const respData = response.data;
                    if (respData.code === 100) {
                        getFirstPageWorks();
                    }
                });
            });
        };

        // 编辑工作
        const editClick = (record: any) => {
            if (editDialog) {
                editDialog.value.showDialog(toRaw(record).resource, (workData: any) => {
                    workData.workStartDate = moment(workData.workStartDate, 'YYYY-MM-DD');
                    workData.workEndDate = moment(workData.workEndDate, 'YYYY-MM-DD');
                    Api.adminEditWork(workData).then((response: any) => {
                        const respData: any = response.data;
                        if (respData.code === 100) {
                            message.success('保存成功');
                        }
                    }).finally(() => {
                        getWorks();
                    });
                });
            }
        };

        // 删除工作
        const deleteClick = (record: any) => {
            console.log('delete work:', toRaw(record));
            Modal.confirm({
                title: '确定是否删除此工作',
                icon: createVNode(ExclamationCircleOutlined),
                onOk: () => {
                    Api.adminDeleteWork(toRaw(record).workId).then((response: any) => {
                        const respData: any = response.data;
                        if (respData.code === 100) {
                            message.success('删除成功');
                        }
                    }).finally(() => {
                        getWorks();
                    });
                },
                onCancel: () => {},
            });
        };

        // 复制工作
        const copyWorkClick = (record: any) => {
            const datas = toRaw(record).resource;
            datas.workId = 0;
            datas.title = `${datas.title} - 复制`;
            datas.workState = 'edit',
            editDialog.value.showDialog(datas, (workData: any) => {
                workData.workStartDate = moment(workData.workStartDate, 'YYYY-MM-DD');
                workData.workEndDate = moment(workData.workEndDate, 'YYYY-MM-DD');
                Api.adminAddWork(workData).then((response: any) => {
                    const respData: any = response.data;
                    if (respData.code === 100) {
                        message.success('保存成功');
                    }
                }).finally(() => {
                    getFirstPageWorks();
                });
            });
        };

        return {
            searchValue,

            columns,
            tableData,
            pagging,
            showUsersClick,
            editClick,
            deleteClick,
            copyWorkClick,
            changePage,
            workPeoples,
            workStateText,
            workStateColor,
            workTypeColor,
            workTypeText,

            getWorks,
            addWorkClick,
            editDialog,
            applicantsDialog,

            getFirstPageWorks,
        };
    },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3409c0fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-body" }
const _hoisted_2 = { class: "search-box" }
const _hoisted_3 = { class: "table-data" }
const _hoisted_4 = { style: {"color":"red","font-weight":"600"} }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "pagging" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_ApplicantsDialog = _resolveComponent("ApplicantsDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_input_search, {
        value: $setup.searchValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchValue) = $event)),
        placeholder: "搜索",
        "enter-button": "",
        onSearch: $setup.searchUser
      }, null, 8, ["value", "onSearch"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        columns: $setup.columns,
        "data-source": $setup.tableData.value,
        pagination: false,
        scroll: { x: 1, y: 1 }
      }, {
        index: _withCtx(({ index }) => [
          _createElementVNode("span", null, _toDisplayString(index), 1)
        ]),
        sex: _withCtx(({ record }) => [
          _createElementVNode("span", null, _toDisplayString(record.sex === 'man' ? '男' : '女'), 1)
        ]),
        blockUser: _withCtx(({ record }) => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(record.blacklisted == 1 ? '已拉黑' : ''), 1)
        ]),
        state: _withCtx(({ record }) => [
          _createElementVNode("span", null, [
            _createVNode(_component_a_tag, {
              color: $setup.workStateColor(record)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.workStateText(record)), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ])
        ]),
        action: _withCtx(({ record }) => [
          _createElementVNode("span", null, [
            _createElementVNode("a", {
              onClick: ($event: any) => ($setup.showUsersClick(record))
            }, "用户信息", 8, _hoisted_5)
          ])
        ]),
        _: 1
      }, 8, ["columns", "data-source"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_a_pagination, {
        onChange: $setup.changePage,
        total: $setup.pagging.total,
        current: $setup.pagging.currentPage,
        pageSize: $setup.pagging.pageSize
      }, null, 8, ["onChange", "total", "current", "pageSize"])
    ]),
    _createVNode(_component_ApplicantsDialog, { ref: "applicantsDialog" }, null, 512)
  ]))
}
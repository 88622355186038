
import { reactive, ref, toRaw } from '@vue/reactivity';
import Api from '@/common/api';
import { message, Modal } from 'ant-design-vue';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import ApplicantsDialog from '@/components/admin/dialog/ApplicantsDialog.vue';
import { createVNode, watch, inject } from 'vue';

export default ({
    name: 'UserTable',
    components: {
        ApplicantsDialog,
    },
    setup() {
        // @hlh
        // 搜索词
        const searchValue = ref('');
        const userTemp: any = reactive({
            value: [{
                phone: '',
                name: '',
            }],
        });
        const searchUser = () => {
            // 前端筛选
            // console.log('searchValue=> ', searchValue.value, tableData.value, userTemp.value);
            // if (searchValue.value.trim().length !== 0) {
            //     if (/^\d+$/.test(searchValue.value.trim())) {
            //         console.log('数字');
            //         tableData.value = userTemp.value.filter(user => {
            //             return user.phone.includes(searchValue.value.trim());
            //         });
            //     } else {
            //         tableData.value = userTemp.value.filter(user => {
            //             return user.name.includes(searchValue.value.trim());
            //         });
            //     }
            // }

            getFilterUsers();
            
        };
        watch(() => searchValue.value, (n) => {
            if (n.trim().length == 0) {
                getUsers();
            }
        });
        const isFirstLoading = inject('isFirstLoading');
        watch(() => (isFirstLoading as { value: number }).value, (n) => {
            if (n > 0) {
                (isFirstLoading as { value: number }).value = -1;
                getUsers();
            }
        });


        const pagging = reactive({
            currentPage: 1,
            pageSize: 10,
            total: 0,
        });
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: 50,
                slots: { customRender: 'index' },
            },
            // @hlh
            // 添加是否被拉黑标志
            {
                key: 'blacklisted',
                dataIndex: 'blacklisted',
                title: '是否被拉黑',
                width: 120,
                slots: { customRender: 'blockUser' },
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: '姓名',
                width: 100,
            },
            {
                key: 'sex',
                dataIndex: 'sex',
                title: '性别',
                slots: { customRender: 'sex' },
            },
            {
                key: 'phone',
                dataIndex: 'phone',
                title: '电话',
            },
            {
                key: 'wechat',
                dataIndex: 'wechat',
                title: '微信号',
            },
            {
                key: 'school',
                dataIndex: 'school',
                title: '学校',
            },
            {
                key: 'age',
                dataIndex: 'age',
                title: '年龄',
            },
            {
                key: 'action',
                title: '操作',
                slots: { customRender: 'action' },
            },
        ];
        const tableData: any = reactive({
            value: [],
        });

        const editDialog: any = ref<null | HTMLElement>(null);
        const applicantsDialog: any = ref<null | HTMLElement>(null);

        const getUsers = () => {
            Api.adminGetUserList(pagging).then((response: any) => {
                const respData = response.data;
                if (response.data.code === 100) {
                    pagging.total = respData.data.count;
                    const users = respData.data.users;
                    if (users && users.length > 0) {
                        const workItems: any[] = [];
                        users.forEach((item: any) => {
                            workItems.push({
                                key: item.userId,
                                blacklisted: item.blacklisted,
                                userId: item.userId,
                                name: item.name,
                                sex: item.sex,
                                phone: item.phone,
                                wechat: item.wechat,
                                school: item.school,
                                age: item.age,
                                grade: item.grade,
                                userRemarks: item.userRemarks,
                                lastModifyTime: `${moment(item.lastModifyTime).format('YYYY-MM-DD')} ${item.lastModifyTime}`,
                                introduce: item.introduce,
                            });
                        });
                        tableData.value = workItems;
                        // @hlh
                        userTemp.value = workItems;
                    } else {
                        tableData.value = [];
                        // @hlh
                        userTemp.value = [];
                    }
                } else {
                    message.error(respData.msg);
                }
            }).catch((error: any) => {
                message.error(error);
            });
        };
        getUsers();

        // @hlh
        const getFilterUsers = () => {
            Api.getUsersByFilter({ currentPage: pagging.currentPage, pageSize: pagging.pageSize, filterData: searchValue.value.trim() }).then((response: any) => {
                const respData = response.data;
                if (response.data.code === 100) {
                    pagging.total = respData.data.count;
                    const users = respData.data.users;
                    if (users && users.length > 0) {
                        const workItems: any[] = [];
                        users.forEach((item: any) => {
                            workItems.push({
                                key: item.userId,
                                blacklisted: item.blacklisted,
                                userId: item.userId,
                                name: item.name,
                                sex: item.sex,
                                phone: item.phone,
                                wechat: item.wechat,
                                school: item.school,
                                age: item.age,
                                grade: item.grade,
                                userRemarks: item.userRemarks,
                                lastModifyTime: `${moment(item.lastModifyTime).format('YYYY-MM-DD')} ${item.lastModifyTime}`,
                                introduce: item.introduce,
                            });
                        });
                        tableData.value = workItems;
                        // @hlh
                        userTemp.value = workItems;
                    } else {
                        tableData.value = [];
                        // @hlh
                        userTemp.value = [];
                    }
                } else {
                    message.error(respData.msg);
                }
            }).catch((error: any) => {
                message.error(error);
            });
        };

        const getFirstPageWorks = () => {
            pagging.currentPage = 1,
                getUsers();
        };

        const workPeoples = (item: any) => {
            return item.total + '/' + item.people;
        };
        const workStateText = (item: any) => {
            let text = '';
            switch (item.state) {
                case 'reservation':
                    if (item.total >= item.people) {
                        text = '满员';
                    } else {
                        text = '预约中';
                    }
                    break;
                case 'finished':
                    text = '已结束';
                    break;
                case 'edit':
                    text = '编辑中';
                    break;
            }
            return text;
        };
        const workStateColor = (item: any) => {
            switch (item.state) {
                case 'reservation':
                    if (item.total >= item.people) {
                        return 'red';
                    }
                    return 'green';
                case 'finished':
                    return 'blue';
                case 'edit':
                    return 'grey';
            }
        };

        const changePage = (page: number, pageSize: number) => {
            pagging.currentPage = page;
            pagging.pageSize = pageSize;
            if (searchValue.value.trim().length !== 0) {
                getFilterUsers();
            }else {
                getUsers();
            }
        };

        const showUsersClick = (record: any) => {
            if (applicantsDialog) {
                applicantsDialog.value.showUserMore(toRaw(record));
            }
        };
        const editClick = (record: any) => {
            if (editDialog) {
                editDialog.value.showDialog(toRaw(record).resource, (workData: any) => {
                    workData.workStartDate = moment(workData.workStartDate, 'YYYY-MM-DD');
                    workData.workEndDate = moment(workData.workEndDate, 'YYYY-MM-DD');
                    Api.adminEditWork(workData).then((response: any) => {
                        const respData: any = response.data;
                        if (respData.code === 100) {
                            message.success('保存成功');
                        }
                    }).finally(() => {
                        getUsers();
                    });
                });
            }
        };
        const deleteClick = (record: any) => {
            console.log('delete work:', toRaw(record));
            Modal.confirm({
                title: '确定是否删除此工作',
                icon: createVNode(ExclamationCircleOutlined),
                onOk: () => {
                    Api.adminDeleteWork(toRaw(record).workId).then((response: any) => {
                        const respData: any = response.data;
                        if (respData.code === 100) {
                            message.success('删除成功');
                        }
                    }).finally(() => {
                        getUsers();
                    });
                },
                onCancel: () => { },
            });
        };
        const copyWorkClick = (record: any) => {
            const datas = toRaw(record).resource;
            datas.workId = 0;
            datas.title = `${datas.title} - 复制`;
            datas.workState = 'edit',
                editDialog.value.showDialog(datas, (workData: any) => {
                    workData.workStartDate = moment(workData.workStartDate, 'YYYY-MM-DD');
                    workData.workEndDate = moment(workData.workEndDate, 'YYYY-MM-DD');
                    Api.adminAddWork(workData).then((response: any) => {
                        const respData: any = response.data;
                        if (respData.code === 100) {
                            message.success('保存成功');
                        }
                    }).finally(() => {
                        getFirstPageWorks();
                    });
                });
        };

        return {
            // @hlh
            searchValue,
            searchUser,
            userTemp,

            columns,
            tableData,
            pagging,
            showUsersClick,
            editClick,
            deleteClick,
            copyWorkClick,
            changePage,
            workPeoples,
            workStateText,
            workStateColor,

            editDialog,
            applicantsDialog,

            getFirstPageWorks,
        };
    },
});

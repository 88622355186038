import moment from 'moment';
const moneyUnityMap: any = {
    day: '天',
    week: '周',
    month: '月',
};

const paymentMethodMap: any = {
    day: '日结',
    week: '周结',
    month: '月结',
};

const sexMap: any = {
    man: '男',
    woman: '女',
};

export default {
    cloneData: (data: any) => JSON.parse(JSON.stringify(data)),
    getUrl: () => window.location.href,

    translateMoneyUnity: (moneyUnity: string) => {
        return moneyUnityMap[moneyUnity];
    },
    translatePaymentMethod: (paymentMethod: string) => {
        return paymentMethodMap[paymentMethod];
    },
    sexText: (sex: string) => {
        return sexMap[sex];
    },
    formatTime: (time: string) => {
        return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
};

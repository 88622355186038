
import { reactive } from '@vue/reactivity';
import { ValidateErrorEntity } from 'ant-design-vue/lib/form/interface';
import { inject, ref, toRaw, UnwrapRef, watch } from 'vue';
import { message } from 'ant-design-vue';
import Api from '@/common/api';
import Utils from '@/common/utils';
import {
    RightOutlined
} from '@ant-design/icons-vue';

interface FormState {
    value: {
        name: string,
        sex: string,
        phone: string,
        wechat: string,
        age: number,
        school: string,
        grade: string,
        introduce: string,
    }
}

export default ({
    components: {
        RightOutlined,
    },
    props: {
        clickShowUserWorks: Function,
    },
    setup() {
        const globalData: any = inject('globalData');

        const EditState = {
            ON: false,
            OFF: true,
        };
        const data: any = reactive({
            isLogin: false,
        });
        const selectOptions = [
            {value: 'man', label: '男'},
            {value: 'woman', label: '女'},
        ];
        const formRef = ref();
        const formRules = {
            name: [
                { required: true, message: '姓名不能为空', trigger: 'change' },
            ],
            sex: [
                { required: true, message: '性别不能为空', trigger: 'change' },
            ],
            phone: [
                { required: true, message: '手机号不能为空', trigger: 'change' },
                { pattern: new RegExp('^1[3-9]\\d{9}$', 'g'), message: '请输入正确手机号', trigger: 'change' }
            ],
            wechat: [
                { required: true, message: '微信号不能为空', trigger: 'change' },
            ],
            age: [
                { required: true, pattern: new RegExp('^\\d+$', 'g'), message: '请输入正确的年龄', trigger: 'blur' }
            ],
        };

        const formState: UnwrapRef<FormState> = reactive({
            value: {
                name: '',
                sex: 'man',
                phone: '',
                wechat: '',
                age: 0,
                school: '',
                grade: '',
                introduce: '',
            },
        });

        const onSubmitOrEdit = () => {
            formRef.value.validate().then(() => {
                const userInfo = toRaw(formState.value);
                console.log('values', formState, toRaw(formState));
                userInfo.age = Number(userInfo.age);
                Api.updateUserInfo(userInfo).then((response: any) => {
                    if (response.data.code === 100) {
                        globalData.userInfo.value = userInfo;
                        message.success('修改成功', 2);
                        data.isEdit = EditState.OFF;
                    }
                }).catch((e: any) => {
                    console.error(e);
                    message.error('服务器错误', 2);
                });
            }).catch((error: ValidateErrorEntity<FormState>) => {
                console.log('error', error);
                message.error('请将信息填写完整', 2);
            });
        }; 

        const getButtonText = () => {
            return '保存';  
        };

        const uesrData: any = window.localStorage.getItem('user');
        if (uesrData) {
            data.isLogin = true;
        }

        watch(globalData.userInfo, (newValue) => {
            const userInfo: any = Utils.cloneData(newValue.value);
            userInfo.age = Number(userInfo.age);
            formState.value = userInfo;
        });

        return {
            data,
            selectOptions,
            formRef,
            formRules,
            formState,
            onSubmitOrEdit,
            getButtonText,
        };
    }
});
